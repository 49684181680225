import './gtm';

import { GAUniversalEvent } from './types';

// When setting up events in GTM, each event needs a custom name.
// It's convenient to use the same name for each of them, and simply
// forward all details for a GA event to be reassigned within the GTM tag.

// Using the same event in GTM means new events can
// be added without additional GTM configuration.
const eventName = 'customEvent';

/**
 * Track a Google Analytics Universal event, using Google Tag Manager's `dataLayer`.
 *
 * Relies on a `customEvent` tag in GTM that forwards event details to GA.
 */
export function trackEvent(gaEvent: GAUniversalEvent) {
	window.dataLayer = window.dataLayer || [];

	const gtmEvent = {
		event: eventName,

		// The name of this object and its properties mustn't change - it's reflected in GTM
		eventDetails: {
			category: gaEvent.category,
			action: gaEvent.action,
			label: gaEvent.label,
			value: gaEvent.value,
		},

		// Future enhancement: also pass events through to GA4
	};

	window.dataLayer.push(gtmEvent);
}
