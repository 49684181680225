import React from 'react';

import PropTypes from 'prop-types';
import type { InferProps } from 'prop-types';

import getAcfBlockProps from 'components/prop-types/acf-block';
import getEnumValidator from 'components/prop-types/enum';

import classNames from 'classnames';

import Link from 'next/link';
import Image, { Crop } from './image';

import { trackEvent } from '../utils/gtm/index';

enum PathwaysLayout {
	TAGLINE_HEADING = 'tagline-heading',
	HEADING_INTRO = 'heading-intro',
	SHOW_ALL = 'show-all',
}

const propTypes = {
	...getAcfBlockProps(
		{
			layout: getEnumValidator(PathwaysLayout),

			items: PropTypes.arrayOf(PropTypes.shape({
				tagline: PropTypes.string,
				title: PropTypes.string,
				description: PropTypes.string,
				image: PropTypes.shape({
					url: PropTypes.string.isRequired,
					alt: PropTypes.string,

					width: PropTypes.number,
					height: PropTypes.number,

					sizes: PropTypes.shape({
						medium_large: PropTypes.string.isRequired,
						['medium_large-width']: PropTypes.number.isRequired,
						['medium_large-height']: PropTypes.number.isRequired,
					}),
				}),
				link: PropTypes.shape({
					url: PropTypes.string.isRequired,
					text: PropTypes.string.isRequired,
				}),
				background: PropTypes.string,
			}).isRequired).isRequired,
		}
	),
};
type PathwaysPropTypes = InferProps<typeof propTypes>;

const Pathways = Object.assign(
	function Pathways(props: PathwaysPropTypes) {
		const content = props.block?.content;

		function trackLinkClick(index: number) {
			return function trackLinkClick(e: React.MouseEvent<HTMLAnchorElement>): void {
				const link = content?.items[index].link?.url ?? undefined;
				trackEvent({
					category: 'Pathways',
					action: 'Link click',
					label: link,
				});
			};
		}

		if (!content) {
			return null;
		}

		const layout = content.layout || PathwaysLayout.TAGLINE_HEADING;

		return (
			<div className={classNames('pathways__layout', `pathways--${layout}`)}>
				{content.items.map((item, i) => (
					<div
						key={i}
						className={classNames('pathways__item', { [`pathways__item--${item.background}`]: !!item.background })}
					>
						<div className="pathways__item__body">
							{
								(item.tagline || item.title || item.description) &&
								<div className="pathways__item__content">
									{
										item.tagline &&
										<span className="pathways__item__tagline">{item.tagline}</span>
									}
									{
										item.title &&
										<h2 className="pathways__item__title">{item.title}</h2>
									}
									{
										item.description &&
										<p className="pathways__item__description">{item.description}</p>
									}
								</div>
							}
							{
								(item.link) &&
								<Link
									href={item.link.url}
								>
									<a
										onClick={trackLinkClick(i)}
										className="no-color-override pathways__item__link"
									>{item.link.text}</a>
								</Link>
							}
							{
								item.image &&
								<>
									{
										item.link ?
										<a
											href={item.link.url}
											onClick={trackLinkClick(i)}
											className="no-color-override pathways__item__image"
										>
											<Image
												lazy={true}
												block={{
													content: {
														...item.image,
														crop: Crop.MEDIUM_LARGE,
													},
												}}
											/>
										</a> :
										<div className="pathways__item__image">
											<Image
												lazy={true}
												block={{
													content: {
														...item.image,
														crop: Crop.MEDIUM_LARGE,
													},
												}}
											/>
										</div>
									}
								</>
							}
						</div>
					</div>
				))}
			</div>
		);
	},

	{ propTypes },
);

export default Pathways;
